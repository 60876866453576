import React from "react"
import { graphql } from "gatsby"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import RubyConverterTool from "../../../utils/RubyConverterTool"
import RubyConvert from "../../../components/RubyConvert"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import FlagMm from "../../../images/flag_mm.svg"
import FlagJp from "../../../images/flag_jp.svg"
import FaceImg01 from "../../../images/interview/01/face_01.jpg"
import FaceImg02 from "../../../images/interview/01/face_02.jpg"
import Img from "gatsby-image"

import css from "../stylesheets/interview.module.scss"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo title={`わからないことを教えあいながら働く | INTERVIEW`} />
      <LayoutInterview lang={`easyja`}>
        <Info2column id={`interview-01`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>
              <RubyConvert>{`{わからない}{ことを}{[教/おし]えあいながら}{[働/はたら]く}`}</RubyConvert>
            </h3>

            <p>
              <RubyConvert>{`{[山崎/やまざき][僚恵/ともえ]さん}`}</RubyConvert>
              <small>
                <RubyConvert>{`{（23[歳/さい]）}`}</RubyConvert>
              </small>{" "}
              × <RubyConvert>{`{ジンジンモーさん}`}</RubyConvert>
              <small>
                <RubyConvert>{`{（27[歳/さい]）}`}</RubyConvert>
              </small>
            </p>

            <p>
              <RubyConvert>{`{ジンジンモーさんは}`}</RubyConvert>
              <span className={`phrase`}>
                <AnchorLink
                  className={`linkUnderline`}
                  to="/basic-information/kaigo/#section-2"
                >
                  <RubyConvert>{`[技能/ぎのう][実習/じっしゅう][生/せい]`}</RubyConvert>
                </AnchorLink>
                です。
              </span>
              <RubyConvert>{`{ミャンマーから}{[来/き]ました。}{[北海道/ほっかいどう]}{[札幌市/さっぽろし]に}{ある}{グループホーム}『`}</RubyConvert>

              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  <RubyConvert>{`{[満快/まんかい]のふる[郷/さと]}{さくら[発寒/はっさむ]$}`}</RubyConvert>
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>
                    <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
                  </h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                  <Img fluid={data.facility5.childImageSharp.fluid} />
                </div>
              </ModalLink>
              <RubyConvert>{`』で{[働/はたら]いています。}{[山崎/やまざき][僚恵/ともえ]さんが}{この}{[施設/しせつ]で}{[働/はたら]きはじめたのは}{ジンジンモーさんより}{[少/すこ]し}{[後/あと]です。}{[2人/ふたり]は}{[年齢/ねんれい]も}{[仕事/しごと]の}{[経験/けいけん]も}{[同/おな]じくらいなので、}{[仲/なか]が}{いいです。}{[互/たが]いに}{[助/たす]けあいながら}{[働/はたら]いています。}{「[仕事/しごと]が}{[楽/たの]しい」と}{[笑顔/えがお]で}{[話/はな]す}{[2人/ふたり]に、}{[仕事/しごと]や}{[生活/せいかつ]について}{[聞/き]きました。}`}</RubyConvert>
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable profileTableIsWideLeft`}>
              <tbody>
                <tr>
                  <th>
                    <RubyConvert>{`{[名前/なまえ]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`ジンジンモーさん`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[勤務先/きんむさき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{さくらCSホールディングス[株式/かぶしき][会社/がいしゃ]}{グループホーム}{『[満快/まんかい]のふる[郷/さと]』}{さくら[発寒/はっさむ]}{[介護/かいご][職員/しょくいん]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[出身地/しゅっしんち]}`}</RubyConvert>
                  </th>
                  <td>
                    <img src={FlagMm} alt="ミャンマー国旗" className={`flag`} />
                    ミャンマー・バゴー
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[年齢/ねんれい]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{27[歳/さい]}{（1993[年/ねん][生/う]まれ）}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[経歴/けいれき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{ダゴン[大学/だいがく]}{[産業化学学部/さんぎょうかがくがくぶ]を}{[卒業/そつぎょう]した}{[後/あと]、}{ヤンゴン[市/し]}{[公衆衛生/こうしゅうえいせい] [指導/しどう ] [員/いん]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[入国/にゅうこく]した}{ときの}{[在留資格/ざいりゅうしかく]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[技能実習/ぎのうじっしゅう]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[来日/らいにち]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{2019[年/ねん]1[月/がつ]}`}</RubyConvert>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={`profileTable profileTableIsWideLeft`}>
              <tbody>
                <tr>
                  <th>
                    <RubyConvert>{`{[名前/なまえ]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[山崎/やまざき][僚恵/ともえ]さん}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[勤務先/きんむさき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{さくらCSホールディングス[株式/かぶしき][会社/がいしゃ]}{グループホーム}{『[満快/まんかい]のふる[郷/さと]』}{さくら[発寒/はっさむ]}{[介護/かいご][職員/しょくいん]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[出身地/しゅっしんち]}`}</RubyConvert>
                  </th>
                  <td>
                    <img src={FlagJp} alt="日本国旗" className={`flag`} />
                    <RubyConvert>{`{[北海道 / ほっかいどう]}{[札幌市 / さっぽろし]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[年齢/ねんれい]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{23[歳/さい]}{（1996[年/ねん][生/う]まれ）}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[経歴/けいれき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[北星学園/ほくせいがくえん][大学/だいがく]}{[社会福祉/しゃかいふくし][学部/がくぶ]}{[福祉心理/ふくししんり][学科/がっか]を}{[卒業/そつぎょう]した}{[後/あと]、}{CSホールディングス[株式/かぶしき][会社/がいしゃ]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[入社/にゅうしゃ]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{2019[年/ねん]4[月/がつ]}`}</RubyConvert>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[介護/かいご]の}{[仕事/しごと]は}{どうですか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ジンジンモーさん`}>
              <p>
                <RubyConvert>{`{わたしは}{[子/こ]どもの}{[頃/ころ]から}{おじいちゃん、}{おばあちゃんと}{[暮/く]らしていました。}{[2人/ふたり]の}{[頭/あたま]を}{[洗/あら]ったり、}{[体/からだ]を}{きれいに}{[拭/ふ]いたり}{していました。}{だから、}{[介護/かいご]の}{[仕事/しごと]を}{していると}{[懐/なつ]かしいですね。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{[介護/かいご]は、}{[利用者/りようしゃ]さんが}{[1人/ひとり]では}{できない}{ことを}{できるように}{[手伝/てつだ]う}{[仕事/しごと]。}{[仏教/ぶっきょう][国/こく]の}{ミャンマーでは、}{「ほかの}{[人/ひと]のために}{いい}{ことを}{すると、}{いつか}{[自分/じぶん]に}{いい}{ことが}{ある」と}{[考/かんが]えられています。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{[介護/かいご]は、}{いい}{[仕事/しごと]。}{[心/こころ]の}{[健康/けんこう]を}{[感/かん]じます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{わたしは、}{[介護/かいご]は「お[世話/せわ]を}{する」}{というより、}{[利用者/りようしゃ]さんを}{「[隣/となり]で [支/ささ]える」}{という}{イメージが}{あります。}{わたしたちの}{[施設/しせつ]では、}{[介護者/かいごしゃ]を}{「パートナー」と}{[呼/よ]んでいます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                <RubyConvert>{`{どう}{したら}{[利用者/りようしゃ]さんが}{[笑顔/えがお]に}{なるのか、}{[暮/く]らしやすくなるのか。}{それを}{[毎日/まいにち]}{[話/はな]しあっています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{まず「[何/なに]が}{[好/す]きか」「[何/なに]を}{したいか」を}{[利用者/りようしゃ]さんに}{[聞/き]く}{ことから}{はじまります。}`}</RubyConvert>
                <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                  <span>
                    <RubyConvert>{`ラジオ[体操/たいそう]`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>
                      <RubyConvert>{`ラジオ[体操/たいそう]`}</RubyConvert>
                    </h3>
                    <p>
                      <RubyConvert>{`{[体力/たいりょく]を}{つけて、}{[健康/けんこう]に}{なる}{ための}{[体操/たいそう]です。}{[音楽/おんがく]に}{あわせて、}{[体/からだ]を}{[動/うご]かします。}{1928[年/ねん]に}{できて、}{ラジオで}{[放送/ほうそう]された}{ことで、}{[日本/にほん][中/じゅう]に}{[広/ひろ]まりました。}{[日本人/にほんじん]の}{ほとんどが}{ラジオ[体操/たいそう]を}{[知/し]っています。}`}</RubyConvert>
                      <a
                        href={`https://www.youtube.com/watch?v=feSVtC1BSeQ`}
                        rel={`noopener noreferrer`}
                        target={`_blank`}
                      >
                        <br />
                        NHK「ラジオ体操」
                      </a>
                    </p>
                  </div>
                </ModalLink>
                <RubyConvert>{`{が}{[好/す]きな}{[人/ひと]も}{いるし、}{[折/お]り[紙/がみ]が}{[好/す]きな}{[人/ひと]、}{[歌/うた]を}{[歌/うた]うのが}{[好/す]きな}{[人/ひと]も}{います。}{[好/す]きな}{ことを}{すると、}{[楽/たの]しくなりますよね。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{[暮/く]らしやすさのためには、}{[部屋/へや]の}{なかで}{[動/うご]きやすくなるように、}{[部屋/へや]の}{レイアウトについて}{[話/はな]しあう}{ことも}{あります。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{わたしも}{[仕事/しごと]に}{[少/すこ]しずつ}{[慣/な]れてきましたが、}{ジンジンモーさんは}{[先輩/せんぱい]で、}{すごく}{[頼/たよ]りに}{なります。}{ジンジンモーさんは}{[介護/かいご]の}{[技術/ぎじゅつ]について}{よく}{[教/おし]えてくれます。}{わたしは}{[代/か]わりに}{[日本語/にほんご]を}{[教/おし]えます。}{わからない}{ことを}{[教/おし]えあって}{いますね。}{でも、}{[2人/ふたり]とも}{うまく}{できないのが}{[料理/りょうり]です（[笑/わらい]）。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  <RubyConvert>{`{そうですね（[笑/わらい]）。}{わたしたちの}{[施設/しせつ]では、}{[介護者/かいごしゃ]が}`}</RubyConvert>
                  <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                    <span>
                      <RubyConvert>{`[食事/しょくじ]`}</RubyConvert>
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>
                        <RubyConvert>{`[食事/しょくじ]`}</RubyConvert>
                      </h3>
                      <p>
                        <RubyConvert>{`{[認知/にんち][症/しょう]の}{[高齢者/こうれいしゃ]が}{[暮/く]らす}{グループホームで、}{[定員/ていいん]が}{[少/すく]ない}{ところでは、}{[介護者/かいごしゃ]が}{[食事/しょくじ]を}{つくります。}{[利用者/りようしゃ]が}{[自分/じぶん]たちで}{[食事/しょくじ]を}{つくる}{ことも}{あります。}{[栄養/えいよう]バランスを}{[考/かんが]えて、}{[何/なに]を}{つくるか}{[決/き]めます。}{ジンジンモーさんが}{[働/はたら]く}{[施設/しせつ]では、}{[利用者/りようしゃ]と}{[介護者/かいごしゃ]が}{[一緒/いっしょ]に}{ごはんを}{[食/た]べます。}{[大/おお]きな}{[施設/しせつ]の}{[場合/ばあい]は、}{[食事/しょくじ]を}{つくる}{[専門/せんもん]の}{[人/ひと]が}{います。}`}</RubyConvert>
                      </p>
                    </div>
                  </ModalLink>
                  <RubyConvert>{`{を}{つくります。} `}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>

            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`山崎 さん`}
              >
                <p>
                  <RubyConvert>{`{ジンジンモーさんは}{[日本/にほん][料理/りょうり]を}{[知/し]らないし、}{わたしは}{[料理/りょうり]を}{した}{ことが}{あまり}{ありませんでした。}{[材料/ざいりょう]を}{[見/み]ながら}{[2人/ふたり]で}{[相談/そうだん]して}{つくっています（[笑/わらい]）。}{[昨日/きのう]は}{[2人/ふたり]で}{[材料/ざいりょう]を}{「[千切/せんぎ]り」に}{しました。}`}</RubyConvert>
                </p>
              </CommentText>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  <RubyConvert>{`{「[千切/せんぎ]り」「[乱切/らんぎ]り」などの}{[切/き]り[方/かた]の}{[違/ちが]いは}{[難/むずか]しいので}{[練習/れんしゅう][中/ちゅう]です！}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[働/はたら]いている}{[施設/しせつ]は}{どんな}{ところですか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image5.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image6.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`山崎 さん`}
              >
                <p>
                  <RubyConvert>{`{わたしたちの}{[施設/しせつ]では}{[地域/ちいき]との}{[関係/かんけい]を}{[大切/たいせつ]にしています。}{たとえば、}{いつも}{[地域/ちいき]の}{[人/ひと]たちが}{[集/あつ]まっている}{お[店/みせ]が}{あって、}{[利用者/りようしゃ]さんも}{[遊/あそ]びに}{[行/い]きます。}{[地域/ちいき]の}{[人/ひと]たちは}{いつも}{わたしたちを}{[見守/みまも]ってくれています。}{「いま}{ひとりで}{[出/で]かけて[行/い]った}{[人/ひと]が}{いたけど、}{[大丈夫/だいじょうぶ]？」と}{[電話/でんわ]してくれる}{ことも}{あります。}`}</RubyConvert>
                </p>
              </CommentText>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  <RubyConvert>{`{[日本/にほん]に}{[来/く]るまでは、}{こんなに}{[地域/ちいき]の}{[人/ひと]との}{[関係/かんけい]が}{あるとは}{[思/おも]いませんでした。}{[施設/しせつ]に}{[初/はじ]めて}{[来/き]た}{[日/ひ]に、}{[施設/しせつ][長/ちょう]は}{[近所/きんじょ]の}{[人/ひと]たちに}{わたしを}{[紹介/しょうかい]してくれました。}{すごく}{[嬉/うれ]しくて、}{その}{[日/ひ]の}{ことは}{よく}{[覚/おぼ]えています。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{[施設/しせつ]に}{[通/かよ]う}{[道/みち]で}{いつも}{[近所/きんじょ]の}{[人/ひと]たちに}{あいさつを}{します。}{ジャガイモや}{ブドウを}{もらう}{ことも}{あります。}{みなさん「[困/こま]った}{ことが}{あったら}{いつでも}{[言/い]ってね」と}{[言/い]ってくれます。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{[利用者/りようしゃ]さんたちが}{[地域/ちいき]の}{[人/ひと]と}{[会/あ]ったり}{[話/はな]したり}{できるように、}{いろいろな}{[場所/ばしょ]に}{[出/で]かけますね。}`}</RubyConvert>
                <ModalLink id={`modal-3`} style={{ display: `inline-block` }}>
                  <span>
                    <RubyConvert>{`[認知症/にんちしょう]カフェ`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>
                      <RubyConvert>{`[認知症/にんちしょう]カフェ`}</RubyConvert>
                    </h3>
                    <p>
                      <RubyConvert>{`{[認知/にんち][症/しょう]の}{[人/ひと]、}{[介護/かいご]する}{[家族/かぞく]、}{[地域/ちいき]の}{[人/ひと]、}{[介護/かいご]}{などの}{[専門/せんもん][職/しょく]の}{[人/ひと]}{などが}{[気軽/きがる]に}{[集/あつ]まって、}{いろいろな}{[話/はなし]を}{する}{[場所/ばしょ]です。}{[日本/にほん][中/じゅう]で}{[開/ひら]かれています。}{[認知/にんち][症/しょう]に}{なると}{[外/そと]に}{[出/で]る}{[機会/きかい]が}{[減/へ]り、}{ずっと}{[家/いえ]に}{いる}{ことが}{[多/おお]くなります。}{[認知/にんち][症/しょう]カフェは、}{そんな}{[認知/にんち][症/しょう]の}{[人/ひと]や}{[家族/かぞく]が}{[外/そと]に}{[出/で]る}{きっかけに}{なる}{[活動/かつどう]です。}{カフェ、}{レクリエーション、}{[勉強会/べんきょうかい]}{など、}{いろいろな}{[内容/ないよう]が}{あります。}`}</RubyConvert>
                    </p>
                  </div>
                </ModalLink>
                <RubyConvert>{`{に}{[行/い]く}{ことも}{あります。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                <RubyConvert>{`{[季節/きせつ]の}{[行事/ぎょうじ]も}{いろいろ}{あります。}{[桜/さくら]の}{[花見/はなみ]を}{[楽/たの]しむ「さくら[祭/まつ]り」の}{ときには、}{わたしは}{ミャンマーの}{[伝統的/でんとうてき]な}{[服/ふく]を}{[着/き]ました。}{そして、}{[一緒/いっしょ]に}{[働/はたら]いている}{ミャンマー[人/じん]と}{[踊/おど]りました。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{[夏/なつ]のお[祭/まつ]りには、}{[近所/きんじょ]の}{[小学生/しょうがくせい]たちが}{15[人/にん]くらい}{[来/き]てくれました。}{[利用者/りようしゃ]さんたちに、}{[歌/うた]や}{[踊/おど]り、}{[手/て]の}{マッサージを}{してくれました。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                <RubyConvert>{`{マッサージは}{すごく}{[人気/にんき]でしたよね！}{[利用者/りようしゃ]さんたちは、}{[子/こ]どもたちと}{たくさん}{[話/はな]していました。}{[子/こ]どもが}{[隣/となり]に}{いるだけで}{[嬉/うれ]しそうでしたね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[日本語/にほんご]を}{[使/つか]う}{[生活/せいかつ]は}{どうですか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  <RubyConvert>{`{[介護/かいご]の}{[仕事/しごと]では、}{コミュニケーションが}{すごく}{[大切/たいせつ]なので、}{[日本語/にほんご]の}{[勉強/べんきょう]は}{[必要/ひつよう]ですね。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{ジンジンモーさんは、}{[単語/たんご]の}{[意味/いみ]を}{[知/し]っていても、}{[文法/ぶんぽう]が}{わからない}{ときが}{ありますね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  <RubyConvert>{`{「に」や}{「を」}{など}{[助詞/じょし]の}{[使/つか]い[方/かた]が}{[難/むずか]しいですね。}{[山崎/やまざき]さんたちが}{いつも}{[助/たす]けてくれます。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{ジンジンモーさんは}{まじめだから、}{[覚/おぼ]えた}{[言葉/ことば]を}{すぐに}{[使/つか]ってみます。}{[業務/ぎょうむ][日誌/にっし]も}{[日本語/にほんご]で}{しっかり}{[書/か]いていますよ。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                <RubyConvert>{`{[業務/ぎょうむ][日誌/にっし]には、}{その}{[日/ひ]の}{[仕事/しごと]で}{よかった}{こと、}{もっと}{よくしたい}{こと、}{これからの}{[目標/もくひょう]}{などを}{[書/か]いています。}{それを}{[先輩/せんぱい]が}{[読/よ]んで、}{[日本語/にほんご]の}{[間違/まちが]いを}{[教/おし]えてくれます。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{[日本語/にほんご]は}{まだ}{うまくないですが、}{[話/はな]すのは}{[好/す]きです。}{[利用者/りようしゃ]の}{[梅津/うめづ]さんみたいな}{[話/はな]し[方/かた]を}{したいです。}{わたしが}{[何/なに]か}{お[手伝/てつだ]いした}{ときに、}{[梅津/うめづ]さんは「いつも}{お[世話/せわ]を}{してくれて}{ありがとう」と}{すごく}{[丁寧/ていねい]に}{[話/はな]してくれます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[日本/にほん]では}{どんな}{[生活/せいかつ]を}{していますか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image9.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image10.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  <RubyConvert>{`{ミャンマーは「[暖/あたた]かい」か}{「[暑/あつ]い」かの}{どちらかです。}{なので、}{[寒/さむ]い}{[場所/ばしょ]での}{[生活/せいかつ]は}{とても}{[心配/しんぱい]でした。}{[日本/にほん]に}{[来/く]る}{[前/まえ]に、}{ミャンマーで}{コートや}{[暖/あたた]かい}{[服/ふく]を}{たくさん}{[買/か]いました。}{[日本/にほん]に}{[来/く]る}{ときは}{[荷物/にもつ]が}{すごく}{[多/おお]かったです（[笑/わらい]）。}{[札幌/さっぽろ]に}{[着/つ]いた}{ときは、}{まわりが}{[全部/ぜんぶ]}{[真/ま]っ[白/しろ]な}{[景色/けしき]でした。}{びっくりしました。}{[日本/にほん]に}{[来/き]て}{[初/はじ]めて}{[買/か]ったのは、}`}</RubyConvert>
                  <ModalLink id={`modal-4`} style={{ display: `inline-block` }}>
                    <span>
                      <RubyConvert>{`{[雪道/ゆきみち]で}{[滑/すべ]らない}{[靴/くつ]$}`}</RubyConvert>
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>
                        <RubyConvert>{`{[雪道/ゆきみち]で}{[滑/すべ]らない}{[靴/くつ]}`}</RubyConvert>
                      </h3>
                      <p>
                        <RubyConvert>{`{[雪/ゆき]が}{[降/ふ]る}{[地域/ちいき]では、}{ふつうの}{[靴/くつ]だと、}{[雪/ゆき]で}{[滑/すべ]ったり、}{[足/あし]が}{ぬれたり、}{[足/あし]が}{[冷/つめ]たくなったり}{します。}{[靴/くつ]の}{[裏/うら]が}{ゴム}{などで}{[滑/すべ]りにくくなっている}{[靴/くつ]、}{[足/あし]が}{ぬれにくい}{[靴/くつ]、}{[足/あし]が}{[暖/あたた]かくなる}{[靴/くつ]が}{おすすめです。}{「ウィンターブーツ」や}{「スノーシューズ」}{など}{という}{[名前/なまえ]で}{[売/う]られている}{ものも}{あるので、}{[店員/てんいん]さんに}{[相談/そうだん]してください。}`}</RubyConvert>
                      </p>
                    </div>
                  </ModalLink>
                  <RubyConvert>{`{です。}{いまは}{[会社/かいしゃ]が}{[用意/ようい]してくれた}{アパートに}{[住/す]んでいます。}{ミャンマー[人/じん]の}{[技能/ぎのう][実習/じっしゅう][生/せい]と}{[2人/ふたり]で}{[住/す]んでいます。}{[地下鉄/ちかてつ]に}{20[分/ぷん]}{[乗/の]って、}{その[後/あと]}{また}{20[分/ぷん]}{[歩/ある]いて}{[施設/しせつ]に}{[通/かよ]っています。}{[休/やす]みの}{[日/ひ]には、}{[必/かなら]ず}{お[母/かあ]さんと}{[電話/でんわ]で}{[話/はな]します。}{[友/とも]だちと}{[買/か]い[物/もの]を}{したり、}{[公園/こうえん]で}{ゆっくり}{[過/す]ごしたり}{するのも}{[好/す]きです。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{わたしたち}{[2人/ふたり]は、}{[休/やす]みの}{[日/ひ]が}{[違/ちが]う}{ことが}{[多/おお]いです。}{でも}{12[月/がつ]には}{[施設/しせつ][長/ちょう]と}{[一緒/いっしょ]に}{イルミネーションを}{[見/み]に}{[行/い]きましたね！}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image11.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                <RubyConvert>{`{[雪/ゆき]に}{イルミネーションが}{[光/ひか]って}{とても}{きれいでしたね。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{[山崎/やまざき]さんとの}{[思/おも]い[出/で]は、}{[誕生/たんじょう][日/び]プレゼントを}{もらった}{ことです。}{[山崎/やまざき]さんが}{[自分/じぶん]で}{つくった}{[人形/にんぎょう]です。}{[嬉/うれ]しくて、}{[袋/ふくろ]に}{[入/い]れたまま}{[部屋/へや]に}{[飾/かざ]っています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{これからの}{[目標/もくひょう]を}{[教/おし]えてください。}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                <RubyConvert>{`{[日本/にほん]で}{[介護/かいご][福祉士/ふくしし]の}{[資格/しかく]を}{[取/と]りたいです。}{ミャンマーにも}{[介護/かいご][施設/しせつ]は}{ありますが、}{[介護/かいご][職員/しょくいん]も}{[技術/ぎじゅつ]も}{まだまだ}{[足/た]りません。}{わたしは}{ミャンマーからの}{[技能/ぎのう][実習/じっしゅう][生/せい]の}{1[期生/きせい]なので、}{ミャンマーに}{[帰/かえ]ったら、}{[介護/かいご]の}{[技術/ぎじゅつ]を}{[教/おし]える}{[先生/せんせい]に}{なりたいと}{[思/おも]っています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                <RubyConvert>{`{[介護/かいご]は、}{[大変/たいへん]な}{[仕事/しごと]}{という}{イメージが}{まだ}{ありますよね。}{でも}{わたしたちは}{その}{イメージを}{[変/か]えていこうと}{しています。}{[施設/しせつ]を}{[利用/りよう]すると、}{[施設/しせつ]の}{[外/そと]との}{[関係/かんけい]が}{なくなりやすいですが、}{わたしは}{[施設/しせつ]の}{[中/なか]と}{[外/そと]を}{つなぐ}{[役割/やくわり]を}{したいと}{[思/おも]っています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image13.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                <RubyConvert>{`{[日本/にほん]で}{[介護/かいご]の}{[仕事/しごと]を}{する}{ために}{[大切/たいせつ]なのは、}{やっぱり}{[日本語/にほんご]を}{[一生懸命/いっしょうけんめい]}{[勉強/べんきょう]する}{ことですね。}{[介護/かいご]の}{[技術/ぎじゅつ]は、}{[仕事/しごと]を}{していると}{だんだん}{[身/み]についていきます。}{しかし、}{[日本語/にほんご]は}{[勉強/べんきょう]しないと}{うまくなりません。}{[介護/かいご]の}{[技術/ぎじゅつ]の}{ほかに、}{[日本/にほん]で}{[働/はたら]いた}{[経験/けいけん]を}{[後輩/こうはい]たちに}{[伝/つた]えていきたいと}{[思/おも]っています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>
                <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
              </p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    <RubyConvert>{`{[満快/まんかい]のふる[郷/さと]}{さくら[発寒/はっさむ]$}`}</RubyConvert>
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>
                      <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
                    </h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                    <Img fluid={data.facility5.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                <RubyConvert>{`{「グループホーム」と}{[呼/よ]ばれる、}{[認知/にんち][症/しょう]の}{[高齢者/こうれいしゃ]のための}{[介護/かいご][施設/しせつ]です。}{『さくら[発寒/はっさむ]』では、}{[定員/ていいん]は}{1[階/かい]と}{2[階/かい]で}{それぞれ}{9[人/にん]ずつです。}{[定員/ていいん]が}{[少/すく]なく、}{[家庭的/かていてき]な}{[雰囲気/ふんいき]です。}{[利用者/りようしゃ]の}{[多/おお]くは}{キッチンが}{ある}{リビングルームで}{1[日/にち]を}{[楽/たの]しみます。}{[季節/きせつ]ごとの}{イベントや、}{[利用者/りようしゃ]の}{お[出/で]かけ、}{[地域/ちいき]の}{[人々/ひとびと]との}{[関係/かんけい]を}{[大切/たいせつ]にしています。}`}</RubyConvert>
              </p>
              <p>
                <a
                  href={`https://sakura-cs.com`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://sakura-cs.com
                </a>
              </p>

              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Mihoko Tsujita
                  <br />
                  Translation by Shinsuke Hayama, Daigo Murotsu（SLOW
                  COMMUNICATION）
                </p>
              </div>

              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/3`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/2`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/01/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/01/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/01/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/01/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/01/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/01/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/01/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/01/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/01/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/01/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/01/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/01/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image13: file(relativePath: { eq: "interview/01/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/01/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/01/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/01/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/01/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility5: file(relativePath: { eq: "interview/01/facility_05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
